<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vs-table ref="table" multiple v-model="selected" :max-items="itemsPerPage" :data="products">
     <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
           <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
            <div
              class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32"
            >
              <span class="mr-2">العمليات</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
     

              <vs-dropdown-item @click="archive">
                <span class="flex items-center">
                  <feather-icon
                    icon="ArchiveIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>استعادة المنتج</span>
                </span>
              </vs-dropdown-item>


             
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
     </div>
      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">الإسم</vs-th>
        <vs-th sort-key="category_id">القسم الرئيسي</vs-th>
        <vs-th sort-key="sub_category_id">القسم الفرعي</vs-th>
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="price">السعر</vs-th>
        <vs-th sort-key="is_visible">الحالة</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td class="product-img-container flex justify-center items-center	" v-if="tr.media.length">
              <img :src="tr.media[0].full_path" class="product-img" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.category.name }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.sub_category.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ tr.sku }}</p>
            </vs-td>

            <vs-td>
              <p class="product-price">{{ tr.price }}LYD</p>
            </vs-td>


            <vs-td>
              <vs-switch val="html" v-model="tr.is_visible" @change="changeStatus(tr.id,tr.is_visible)"> Html
              </vs-switch>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination class="mt-4" :total="lastPage" v-model="current_Page" @change="getData(current_Page)">
    </vs-pagination>
  </div>
</template>


<script>
  import moduleProduct from "@/store/products/moduleProduct.js";
  export default {
    data() {
      return {
        search:'',
        selected: [],
        products: [],
        isMounted: false,
        status: '',
        addNewDataSidebar: false,
        sidebarData: {},
        current_Page: 1,
        itemsPerPage: 5,
        lastPage: 0,
        product_id: '',
        totalItems: 0,
      };
    },
    computed: {
      currentPage() {
        if (this.isMounted) {
          return this.$refs.table.currentx;
        }
        return 0;
      },
      queriedItems() {
        return this.$refs.table ?
          this.$refs.table.queriedResults.length :
          this.products.length;
      },
    },

    methods: {
      getData(page) {

        this.$store.dispatch("product/fetchArchived", page).then((response) => {
          this.products = response.data.products.data;
          this.itemsPerPage = response.data.products.per_page;
          this.totalItems = response.data.products.total;
          this.lastPage = response.data.products.last_page;

          this.$vs.loading.close();
        });
      },
    archive() {
      var bodyFormData = new FormData();
      bodyFormData.set("id", this.selected.map(item => {
        return item.id
      }));
      bodyFormData.set("status", 0 );

      this.$store
        .dispatch("product/archive", bodyFormData)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تغيير حالة المنتج بنجاح",
            color: "success",
          });
        })
        .catch(() => {
          this.$vs.notify({
            title: "حدث خطأ",
            text: "يرجى اعادة المحاولة",
            color: "danger",
          });
        });
    },
    },
    mounted() {
      this.$vs.loading()
    },
    created() {
      if (!moduleProduct.isRegistered) {
        this.$store.registerModule("product", moduleProduct);
        moduleProduct.isRegistered = true;
      }

    },
  };

</script>

<style lang="scss">
  #data-list-thumb-view {
    .vs-con-table {
      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-left: 1.5rem;
        margin-right: 1.5rem;

        >span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search {
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr {
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

          td {
            padding: 10px;

            &:first-child {
              border-top-left-radius: 0.5rem;
              border-bottom-left-radius: 0.5rem;
            }

            &:last-child {
              border-top-right-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }

            &.product-img-container {
              height: 110px;

              // background: #fff;

              span {
                display: flex;
                justify-content: flex-start;
              }

              .product-img {
                height: 68px;
              }
            }
          }

          td.td-check {
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead {
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text {
            text-transform: uppercase;
            font-weight: 600;
          }
        }

        th.td-check {
          padding: 0 15px !important;
        }

        tr {
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }

</style>
