import axios from "@/axios.js"

export default {
    fetchProducts({commit},params){

        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/products/index`,{params:params})
            .then((response)=>{
                commit("SET_META_DATA", response.data.products.meta);
                commit(`SET_PRODUCTS_DATA`,response.data.products.data);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    filterProducts({},value){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/products/search`,value)
            .then((response)=>{
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    fetchCategories({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('api/dashboard/categories/index')
            .then((response)=>{
                commit('SET_CATEGORIES',response.data.categories);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },
    fetchBrands({commit}){
        return new Promise((resolve,reject)=>{
            axios.get('api/dashboard/brands')
            .then((response)=>{
                commit('SET_BRANDS',response.data.brands);
                resolve(response)
            }).catch((error)=>{
                reject(error)
            })
        })
    },

    createNewProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('api/dashboard/products/store',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    importProducts({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('api/dashboard/products/import',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    importUpdatedProducts({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post('api/dashboard/products/import-updated',payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

    updateProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/products/update/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

    duplicateProduct({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/products/duplicate/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

    editProduct({commit},payload){
        commit('SET_PRODUCT_DATA', payload)
    },
    deleteProduct({commit},id){
        return new Promise((resolve,reject)=>{
            axios.get(`api/dashboard/products/delete/${id}`)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

    updatePriceAndQuantity({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/products/update-price-quantity/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },

    changeStatus({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/products/change-status/${payload.get('id')}`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    archive({commit},payload){
        return new Promise((resolve,reject)=>{
            axios.post(`api/dashboard/products/archive-product`,payload)
            .then((response)=>{
                resolve(response)
            })
            .catch((error)=>{
                reject(error)
            })
        })
    },
    fetchArchived({commit},page){
      return new Promise((resolve,reject)=>{
          axios.get(`api/dashboard/products/archive?page=${page}`)
          .then((response)=>{
              resolve(response)
          }).catch((error)=>{
              reject(error)
          })
      })
  }


}
